// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

export class assets extends _Base {
    /**
     * Get a list of all group images available.
     */
    async images_group(): Promise<ApiResult<Array<string>, never>> {
        return this.request({
            path: "/assets/images_group",
            method: "GET",
            parseError: false,
        })
    }

    /**
     * Get a list of all user images available.
     */
    async images_user(): Promise<ApiResult<Array<string>, never>> {
        return this.request({
            path: "/assets/images_user",
            method: "GET",
            parseError: false,
        })
    }
}