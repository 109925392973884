// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

import { youtube } from "./youtube"

export class player extends _Base {
    /**
     * All proxied and cached interactions with the youtube api.
     */
    youtube = new youtube(this.config)


}