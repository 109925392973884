// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

import { account } from "./account"
import { auth } from "./auth"
import { assets } from "./assets"
import { account_permission } from "./account_permission"
import { group } from "./group"
import { player } from "./player"
import { issue } from "./issue"

import type { Root } from "../../api/Root";

/**
 * The base router containing all functionality of GroupTube.
 */
export class root extends _Base {
    /**
     * All account interactions.
     */
    account = new account(this.config)
    /**
     * Everything to do with authentication (email and password).
     */
    auth = new auth(this.config)
    assets = new assets(this.config)
    account_permission = new account_permission(this.config)
    /**
     * Interactions with groups.
     */
    group = new group(this.config)
    player = new player(this.config)
    /**
     * All interactions with issues.
     */
    issue = new issue(this.config)

    async get_root(): Promise<ApiResult<Root, never>> {
        return this.request({
            path: "/",
            method: "GET",
            parseError: false,
        })
    }
}