/* ============================================================================================== */
/*                                             Result                                             */
/* ============================================================================================== */

export type Result<T, E> = { ok: true; value: T } | { ok: false; error: E }

export const Ok = <T>(value: T): Result<T, never> => {
    return { ok: true, value }
}
export const Err = <E>(error: E): Result<never, E> => {
    return { ok: false, error }
}

/* ============================================================================================== */
/*                                            ApiError                                            */
/* ============================================================================================== */

export type ApiError =
    | { type: "Network" }
    | { type: "InvalidResponse"; content: string }

/* ============================================================================================== */
/*                                            ApiResult                                           */
/* ============================================================================================== */

export type ApiResult<T, E> = Result<T, E | ApiError>

/* ============================================================================================== */
/*                                             Config                                             */
/* ============================================================================================== */

export interface Config {
    /** The base url without a trailing slash (e.g. "https://api.group.tube") */
    baseUrl: string
    headers: Record<string, string>
}

/* ============================================================================================== */
/*                                              Base                                              */
/* ============================================================================================== */

export class Base {
    constructor(protected config: Config) { }

    protected async request({
        path,
        method,
        query,
        input,
        headers = {},
        include_cookies = false,
        parseOutput = true,
        parseError = true,
    }: {
        path: string
        method: string
        query?: Record<string, string | number | boolean>
        input?: unknown
        headers?: Record<string, string>
        include_cookies?: boolean
        parseOutput?: boolean
        parseError?: boolean
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }): Promise<ApiResult<any, any>> {
        const config = {
            method,
            headers,
            body: undefined as undefined | BodyInit,
            credentials: "omit" as RequestCredentials,
        }
        if (input !== undefined) {
            config.headers["Content-Type"] = "application/json"
            config.body = JSON.stringify(input)
        }
        if (include_cookies) {
            config.credentials = "include"
        }

        const queryString = query
            ? "?" + new URLSearchParams(query as any).toString()
            : ""

        let response: Response
        let body: string
        try {
            response = await fetch(
                `${this.config.baseUrl}${path}${queryString}`,
                config
            )
            body = await response.text()
        } catch (error) {
            return Err({ type: "Network" })
        }


        try {
            if (response.ok) {
                if (parseOutput) {
                    return Ok(JSON.parse(body))
                } else {
                    return Ok(undefined)
                }
            } else {
                if (parseError) {
                    return Err(JSON.parse(body))
                } else {
                    return Err(undefined)
                }
            }
        } catch (error) {
            return Err({ type: "InvalidResponse", content: body })
        }
    }
}
