// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

import type { AuthError } from "../../../api/AuthError";
import type { AuthResponse } from "../../../api/AuthResponse";
import type { ChangeEmailCompleteData } from "../../../api/ChangeEmailCompleteData";
import type { ChangeEmailCompleteError } from "../../../api/ChangeEmailCompleteError";
import type { ChangeEmailRequestData } from "../../../api/ChangeEmailRequestData";
import type { ChangeEmailRequestError } from "../../../api/ChangeEmailRequestError";
import type { ChangePasswordData } from "../../../api/ChangePasswordData";
import type { ChangePasswordError } from "../../../api/ChangePasswordError";
import type { LoginData } from "../../../api/LoginData";
import type { LoginError } from "../../../api/LoginError";
import type { RegisterData } from "../../../api/RegisterData";
import type { RegisterError } from "../../../api/RegisterError";
import type { RenewError } from "../../../api/RenewError";
import type { ResetPasswordCompleteData } from "../../../api/ResetPasswordCompleteData";
import type { ResetPasswordCompleteError } from "../../../api/ResetPasswordCompleteError";
import type { ResetPasswordRequestData } from "../../../api/ResetPasswordRequestData";
import type { ResetPasswordRequestError } from "../../../api/ResetPasswordRequestError";
import type { VerifyEmailData } from "../../../api/VerifyEmailData";
import type { VerifyEmailError } from "../../../api/VerifyEmailError";

/**
 * Everything to do with authentication (email and password).
 */
export class auth extends _Base {
    /**
     * Primary login for a user.
     * Authenticate with a email and password, return an auth token as output and a renew token stored in cookies.
     */
    async login(
        input: LoginData,
    ): Promise<ApiResult<AuthResponse, LoginError>> {
        return this.request({
            path: "/auth/login",
            method: "POST",
            input,
            include_cookies: true,
        })
    }

    /**
     * Logout functionality, deletes the renew cookie of a client.
     */
    async logout(): Promise<ApiResult<undefined, never>> {
        return this.request({
            path: "/auth/logout",
            method: "POST",
            parseOutput: false,
            parseError: false,
            include_cookies: true,
        })
    }

    /**
     * Renew authentication by checking for the renew token in cookie headers, and returning a new auth and renew token.
     */
    async renew(): Promise<ApiResult<AuthResponse | null, RenewError>> {
        return this.request({
            path: "/auth/renew",
            method: "POST",
            include_cookies: true,
        })
    }

    /**
     * Registers a new account with the given login and account data.
     */
    async register(
        input: RegisterData,
    ): Promise<ApiResult<AuthResponse, RegisterError>> {
        return this.request({
            path: "/auth/register",
            method: "POST",
            input,
            include_cookies: true,
        })
    }

    async verify_email(
        input: VerifyEmailData,
    ): Promise<ApiResult<undefined, VerifyEmailError>> {
        return this.request({
            path: "/auth/verify_email",
            method: "POST",
            input,
            parseOutput: false,
        })
    }

    async change_password(
        input: ChangePasswordData,
    ): Promise<ApiResult<undefined, ChangePasswordError | AuthError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/auth/change_password",
            method: "POST",
            input,
            headers,
            parseOutput: false,
        })
    }

    async change_email_complete(
        input: ChangeEmailCompleteData,
    ): Promise<ApiResult<undefined, ChangeEmailCompleteError>> {
        return this.request({
            path: "/auth/change_email_complete",
            method: "POST",
            input,
            parseOutput: false,
        })
    }

    async change_email_request(
        input: ChangeEmailRequestData,
    ): Promise<ApiResult<undefined, ChangeEmailRequestError | AuthError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/auth/change_email_request",
            method: "POST",
            input,
            headers,
            parseOutput: false,
        })
    }

    async reset_password_complete(
        input: ResetPasswordCompleteData,
    ): Promise<ApiResult<undefined, ResetPasswordCompleteError>> {
        return this.request({
            path: "/auth/reset_password_complete",
            method: "POST",
            input,
            parseOutput: false,
        })
    }

    async reset_password_request(
        input: ResetPasswordRequestData,
    ): Promise<ApiResult<undefined, ResetPasswordRequestError>> {
        return this.request({
            path: "/auth/reset_password_request",
            method: "POST",
            input,
            parseOutput: false,
        })
    }
}