// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

import type { ChannelItemsApiError } from "../../../../api/ChannelItemsApiError";
import type { PlayerItemInfo } from "../../../../player/PlayerItemInfo";
import type { PlaylistItemsApiError } from "../../../../api/PlaylistItemsApiError";
import type { SearchApiError } from "../../../../api/SearchApiError";
import type { VideoApiError } from "../../../../api/VideoApiError";
import type { YouTubeGetChannelItemsQuery } from "../../../../api/YouTubeGetChannelItemsQuery";
import type { YouTubeGetPlaylistItemsQuery } from "../../../../api/YouTubeGetPlaylistItemsQuery";
import type { YouTubeGetVideoQuery } from "../../../../api/YouTubeGetVideoQuery";
import type { YouTubeGetVideosOutput } from "../../../../api/YouTubeGetVideosOutput";
import type { YouTubeGetVideosQuery } from "../../../../api/YouTubeGetVideosQuery";
import type { YouTubeSearchQuery } from "../../../../api/YouTubeSearchQuery";
import type { YouTubeSearchResultId } from "../../../../yt_api/YouTubeSearchResultId";

/**
 * All proxied and cached interactions with the youtube api.
 */
export class youtube extends _Base {
    async search(
        query: YouTubeSearchQuery,
    ): Promise<ApiResult<Array<YouTubeSearchResultId>, SearchApiError>> {
        return this.request({
            path: "/player/youtube/search",
            method: "POST",
            query: query as any,
        })
    }

    async get_video(
        query: YouTubeGetVideoQuery,
    ): Promise<ApiResult<PlayerItemInfo, VideoApiError>> {
        return this.request({
            path: "/player/youtube/get_video",
            method: "POST",
            query: query as any,
        })
    }

    async get_videos(
        query: YouTubeGetVideosQuery,
    ): Promise<ApiResult<YouTubeGetVideosOutput, VideoApiError>> {
        return this.request({
            path: "/player/youtube/get_videos",
            method: "POST",
            query: query as any,
        })
    }

    async get_playlist_items(
        query: YouTubeGetPlaylistItemsQuery,
    ): Promise<ApiResult<Array<string>, PlaylistItemsApiError>> {
        return this.request({
            path: "/player/youtube/get_playlist_items",
            method: "POST",
            query: query as any,
        })
    }

    async get_channel_items(
        query: YouTubeGetChannelItemsQuery,
    ): Promise<ApiResult<Array<string>, ChannelItemsApiError>> {
        return this.request({
            path: "/player/youtube/get_channel_items",
            method: "POST",
            query: query as any,
        })
    }
}