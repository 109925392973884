// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

import type { AuthError } from "../../../api/AuthError";
import type { CreateGroupError } from "../../../api/CreateGroupError";
import type { GetGroupQuery } from "../../../api/GetGroupQuery";
import type { GetMyGroup } from "../../../api/GetMyGroup";
import type { GetMyGroupError } from "../../../api/GetMyGroupError";
import type { GetMyGroupQuery } from "../../../api/GetMyGroupQuery";
import type { GetOrCreateGroupError } from "../../../api/GetOrCreateGroupError";
import type { GroupData } from "../../../api/GroupData";
import type { ListGroup } from "../../../api/ListGroup";
import type { ListMyGroups } from "../../../api/ListMyGroups";
import type { ListMyGroupsError } from "../../../api/ListMyGroupsError";
import type { PermissionError } from "../../../api/PermissionError";

/**
 * Interactions with groups.
 */
export class group extends _Base {
    /**
     * Get a group's profile.
     */
    async get(
        query: GetGroupQuery,
    ): Promise<ApiResult<GroupData, GetOrCreateGroupError>> {
        return this.request({
            path: "/group/get",
            method: "GET",
            query: query as any,
        })
    }

    /**
     * Creates a completely new group with defaults and returns its id.
     *
     * Used by the main "Create Group" button on the homepage.
     */
    async create(): Promise<ApiResult<string, CreateGroupError>> {
        return this.request({
            path: "/group/create",
            method: "POST",
        })
    }

    /**
     * Get a list of all groups an account is a member of, together with their member and online user count.
     */
    async list_my(): Promise<ApiResult<Array<ListMyGroups>, ListMyGroupsError | AuthError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/group/list_my",
            method: "POST",
            headers,
        })
    }

    /**
     * Gets a group together with its member and online user count.
     */
    async get_my(
        query: GetMyGroupQuery,
    ): Promise<ApiResult<GetMyGroup, GetMyGroupError>> {
        return this.request({
            path: "/group/get_my",
            method: "POST",
            query: query as any,
        })
    }

    /**
     * # Admin Section
     * Get a list of all open groups together with their online user count.
     */
    async list(): Promise<ApiResult<Array<ListGroup>, AuthError | PermissionError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/group/list",
            method: "POST",
            headers,
        })
    }
}