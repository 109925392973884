// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

import type { AuthError } from "../../../api/AuthError";
import type { CreateIssueData } from "../../../api/CreateIssueData";
import type { CreateIssueError } from "../../../api/CreateIssueError";
import type { CreateIssueResult } from "../../../api/CreateIssueResult";
import type { GetIssueData } from "../../../api/GetIssueData";
import type { GetIssueError } from "../../../api/GetIssueError";
import type { GetIssueQuery } from "../../../api/GetIssueQuery";
import type { IssueBriefData } from "../../../api/IssueBriefData";
import type { IssueDeleteError } from "../../../api/IssueDeleteError";
import type { IssueDeleteQuery } from "../../../api/IssueDeleteQuery";
import type { ListIssuesError } from "../../../api/ListIssuesError";
import type { PermissionError } from "../../../api/PermissionError";

/**
 * All interactions with issues.
 */
export class issue extends _Base {
    /**
     * Creates a new issue and returns put-urls to which to upload declared files to.
     */
    async create(
        input: CreateIssueData,
    ): Promise<ApiResult<CreateIssueResult, CreateIssueError | AuthError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/issue/create",
            method: "POST",
            input,
            headers,
        })
    }

    /**
     * # Admin Section
     * Delete an issue by id.
     */
    async delete(
        query: IssueDeleteQuery,
    ): Promise<ApiResult<undefined, IssueDeleteError | AuthError | PermissionError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/issue/delete",
            method: "POST",
            query: query as any,
            headers,
            parseOutput: false,
        })
    }

    /**
     * # Admin Section
     * Get an issue by id, also returns read-urls for the attached files.
     */
    async get(
        query: GetIssueQuery,
    ): Promise<ApiResult<GetIssueData, GetIssueError | AuthError | PermissionError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/issue/get",
            method: "POST",
            query: query as any,
            headers,
        })
    }

    /**
     * # Admin Section
     * List all issues, only returns a "preview" without the full content.
     */
    async list(): Promise<ApiResult<Array<IssueBriefData>, ListIssuesError | AuthError | PermissionError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/issue/list",
            method: "POST",
            headers,
        })
    }
}