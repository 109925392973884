// This file was generated by [tapi](). Do not edit this file manually.

import { Base as _Base, type ApiResult } from "$bindings/tapi/internal"

import type { AccountPermissionData } from "../../../api/AccountPermissionData";
import type { AuthError } from "../../../api/AuthError";
import type { GetMyAccountPermissionError } from "../../../api/GetMyAccountPermissionError";
import type { ListAccountPermissionError } from "../../../api/ListAccountPermissionError";
import type { PermissionError } from "../../../api/PermissionError";

export class account_permission extends _Base {
    /**
     * List all account's permissions.
     */
    async list(): Promise<ApiResult<Array<AccountPermissionData>, ListAccountPermissionError | AuthError | PermissionError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/account_permission/list",
            method: "GET",
            headers,
        })
    }

    /**
     * Get an account's permission (admin section) based on the given authentication.
     * Is `None` if account has no permissions.
     */
    async get_my(): Promise<ApiResult<AccountPermissionData | null, GetMyAccountPermissionError | AuthError>> {
        const headers: Record<string, string> = {}
        if (this.config.headers["authorization"] !== undefined) {
            headers["authorization"] = this.config.headers["authorization"]
        }
        return this.request({
            path: "/account_permission/get_my",
            method: "GET",
            headers,
        })
    }
}